import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "selectedField",
    "criteriaNumericFields",
    "criteriaSelectFields",
    "criteriaMultiselectFields",
    "criteriaBooleanFields",
    "criteriaTextFields",
    "selectComparisonInput",
    "selectComparisonOutput",
    "multiselectComparisonInput",
    "multiselectComparisonOutput",
    "units"
  ]
  static values = { fieldOptions: Object, criteriaOptions: Object, selectedOptions: Array }

  connect() {
    this.criteriaChanged()
    this.selectComparisonTriggerChanged()
    this.multiselectComparisonTriggerChanged()
  }

  criteriaChanged() {
    let selectedValue = this.selectedFieldTarget.value
    let fieldTypeToShow = this.fieldOptionsValue[selectedValue]

    this.updateShownFields(fieldTypeToShow)
    this.updateCriteriaSelectOptions(fieldTypeToShow, selectedValue)
    this.updateUnits(selectedValue)
  }

  updateShownFields(fieldTypeToShow) {
    switch(fieldTypeToShow) {
      case 'number_field':
        this.criteriaNumericFieldsTarget.removeAttribute("hidden")
        this.criteriaSelectFieldsTarget.setAttribute("hidden", true)
        this.criteriaMultiselectFieldsTarget.setAttribute("hidden", true)
        this.criteriaBooleanFieldsTarget.setAttribute("hidden", true)
        this.criteriaTextFieldsTarget.setAttribute("hidden", true)
        break;
      case 'select_field':
        this.criteriaNumericFieldsTarget.setAttribute("hidden", true)
        this.criteriaSelectFieldsTarget.removeAttribute("hidden")
        this.criteriaMultiselectFieldsTarget.setAttribute("hidden", true)
        this.criteriaBooleanFieldsTarget.setAttribute("hidden", true)
        this.criteriaTextFieldsTarget.setAttribute("hidden", true)
        break;
      case 'multiselect_field':
        this.criteriaNumericFieldsTarget.setAttribute("hidden", true)
        this.criteriaSelectFieldsTarget.setAttribute("hidden", true)
        this.criteriaMultiselectFieldsTarget.removeAttribute("hidden")
        this.criteriaBooleanFieldsTarget.setAttribute("hidden", true)
        this.criteriaTextFieldsTarget.setAttribute("hidden", true)
        break;
      case 'boolean_field':
        this.criteriaNumericFieldsTarget.setAttribute("hidden", true)
        this.criteriaSelectFieldsTarget.setAttribute("hidden", true)
        this.criteriaMultiselectFieldsTarget.setAttribute("hidden", true)
        this.criteriaBooleanFieldsTarget.removeAttribute("hidden")
        this.criteriaTextFieldsTarget.setAttribute("hidden", true)
        break;
      case 'text_field':
        this.criteriaNumericFieldsTarget.setAttribute("hidden", true)
        this.criteriaSelectFieldsTarget.setAttribute("hidden", true)
        this.criteriaMultiselectFieldsTarget.setAttribute("hidden", true)
        this.criteriaBooleanFieldsTarget.setAttribute("hidden", true)
        this.criteriaTextFieldsTarget.removeAttribute("hidden")
        break;
      default:
        this.criteriaNumericFieldsTarget.setAttribute("hidden", true)
        this.criteriaSelectFieldsTarget.setAttribute("hidden", true)
        this.criteriaMultiselectFieldsTarget.setAttribute("hidden", true)
        this.criteriaBooleanFieldsTarget.setAttribute("hidden", true)
        this.criteriaTextFieldsTarget.setAttribute("hidden", true)
    }
  }

  updateCriteriaSelectOptions(fieldTypeToShow, selectedValue) {
    let selectOptions = []
    let target = this.selectComparisonOutputTarget

    if (!['select_field','multiselect_field'].includes(fieldTypeToShow)) {
      var length = target.options.length
      var i
      for (i = length-1; i >= 0; i--) {
        target.options[i] = null
      }
    } else {
      if (fieldTypeToShow == 'select_field') {
        target = this.selectComparisonOutputTarget
      } else {
        target = this.multiselectComparisonOutputTarget
      }
      selectOptions = this.criteriaOptionsValue[selectedValue]
      if(target.tomselect) {
        // Destroying the fancy select before changing the items fixes some issues when changing the field option.
        target.tomselect.destroy()
      }
      target.innerHTML = ""
      selectOptions.forEach((o) => {
        var opt = document.createElement('option')
        opt.value = o[1]
        opt.innerHTML = o[0]
        if (this.selectedOptionsValue.includes(o[1])) {
          opt.setAttribute("selected", true)
        }
        target.appendChild(opt)
      })
      if(fieldTypeToShow == 'multiselect_field') {
        // Re-enable the fancy select.
        window.enableTomSelect(target)
      }
    }
  }

  selectComparisonTriggerChanged() {
    let selectedValue = this.selectComparisonInputTarget.value

    if(this.selectComparisonOutputTarget.tomselect) {
      this.selectComparisonOutputTarget.tomselect.destroy()
    }
    switch(selectedValue) {
      case 'is_one_of':
        this.selectComparisonOutputTarget.setAttribute("multiple", true)
        window.enableTomSelect(this.selectComparisonOutputTarget)
        break;
      default:
        this.selectComparisonOutputTarget.removeAttribute("multiple")
    }
  }

  multiselectComparisonTriggerChanged() {
    let selectedValue = this.multiselectComparisonInputTarget.value

    if(this.multiselectComparisonOutputTarget.tomselect) {
      this.multiselectComparisonOutputTarget.tomselect.destroy()
    }
    switch(selectedValue) {
      case 'all_selected':
      case 'none_selected':
      case 'is_one_of':
        this.multiselectComparisonOutputTarget.setAttribute("multiple", true)
        window.enableTomSelect(this.multiselectComparisonOutputTarget)
        break;
      default:
        this.multiselectComparisonOutputTarget.removeAttribute("multiple")
    }
  }

  updateUnits(selectedValue) {
    let yearsIf = ["age_difference", "tenure", "experience"]

    if (!yearsIf.includes(selectedValue)) {
      this.unitsTarget.textContent = ""
    } else if (yearsIf.includes(selectedValue)) {
      this.unitsTarget.textContent = "years"
    }
  }
}

