document.addEventListener("turbo:load", () => {
  const autoHeightTextareas = document.getElementsByClassName('auto-height');
  Array.from(autoHeightTextareas).forEach(element => {
    element.style.height = 'auto';
    const resize = function() {
      element.style.height = element.scrollHeight + 'px';
    };
    element.addEventListener('input', resize);
    window.addEventListener('load', resize);
    const resizeObserver = new ResizeObserver(() => {
      resize();
    });
    resizeObserver.observe(element);
  });
})