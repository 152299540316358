import TomSelect from "tom-select"

window.enableTomSelect = function(element) {
  if(!element.tomselect) {
    new TomSelect(element, {
      plugins: ["remove_button"],
      hidePlaceholder: false
    });
  }
}

document.addEventListener("turbo:load", () => {
  let multiselects = document.querySelectorAll('select[multiple]')
  multiselects.forEach(element => {
    enableTomSelect(element)
  })
})