import { Controller } from "@hotwired/stimulus"
import Sortble from "sortablejs"

export default class extends Controller {
  static values = { url: String, method: String, authenticityToken: String }

  connect() {
    this.sortable = Sortble.create(this.element, {
      animation: 150,
      onEnd: this.end.bind(this),
      store: {
        set: this.store.bind(this)
      }
    })
  }

  store(sortable) {
    // The elements (divs) in the new order
    var items = this.element.querySelectorAll('[data-id]')

    // The id of the item based on data-id
    // item.dataset.id
    var orderedIds = Array.from(items, item => item.dataset.id)
    console.log(orderedIds)

    console.log(this.urlValue)
    console.log(this.methodValue)

    fetch(this.urlValue + ".json", {
      method: this.methodValue,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.authenticityTokenValue
      },
      body: JSON.stringify({ ordered_ids: orderedIds })
    })
  }

  end(event) {
    // The element (div) that was moved
    // var item = event.item

    // The id of the item based on data-id
    // var id = item.dataset.id
  }
}
